<template>
    <div class="pageContainer targetPage flexCloumn">
        <el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">


            <div class="flexStart flex_1" style="flex-wrap:wrap;">
                <span>选择组织：</span>
                <tchtree v-model="deptId" @change="searchData"></tchtree>

                <span v-if="$store.state.pmid == 215">届次：</span>
                <el-select v-if="$store.state.pmid == 215" clearable v-model="searchForm.school_year" size="small"
                    placeholder="请选择" style="max-width:200px;" @change="searchData">
                    <el-option v-for="item in schoolYears" :key="item.value" :label="item.title" :value="item.value">
                    </el-option>
                </el-select>

                <span :class="$store.state.pmid == 215 ? 'ml10' : ''">请输入关键字：</span>
                <el-input v-model="searchForm.keyword" size="small" placeholder="请输入关键字，多关键字使用空格隔开" clearable
                    style="width: 200px;" />

                <span class="ml10">状态：</span>
                <el-select v-model="searchForm.biye" size="small" style="width:100px;">
                    <el-option :value="0" label="未毕业"></el-option>
                    <el-option :value="1" label="已毕业"></el-option>
                </el-select>

                <el-button class="ml10" size="small" icon="el-icon-search" @click="searchData()"
                    type="primary">查询</el-button>
            </div>


            <div class="flexStart" style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;margin-top:10px;">

                <el-button icon="el-icon-download" size="small" type="text">
                    <a href="/xls/学生成绩导入模板.xlsx" style="color:inherit;">下载模板</a>
                </el-button>
                <el-upload style="display: inline-block; margin-right: 10px" action :http-request="uploadTemp"
                    :on-success="uploadFiles" :show-file-list="false" name="image">
                    <el-button icon="el-icon-position" style="margin-left:10px" size="small" type="primary"
                        plain>导入</el-button>
                </el-upload>

                <el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" style="margin-left: 10px"
                    size="small" type="primary" plain @click="tongbuJava">迁移老版本</el-button>
            </div>

        </el-row>
        <div class="dividerBar"></div>

        <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
            style="width:fit-content;width:100%;">
            <el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
            <el-table-column label="学号" prop="stu_code" width="135" align="center"></el-table-column>
            <el-table-column label="姓名" prop="stu_name" width="120" align="center" />
            <el-table-column label="班级" prop="class_name" width="150px" show-overflow-tooltip
                align="center"></el-table-column>

            <el-table-column label="操作" width="140" align="center">
                <template slot-scope="scope">
                    <el-tag class="pointer" @click="viewScoreList(scope.row)" type="success" size="mini">查看成绩</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
            :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
            :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
        </el-pagination>

        <el-dialog :visible.sync="dialog1Form" :close-on-click-modal="false" :modal-append-to-body="false"
            :destroy-on-close="true" custom-class="cus_dialog" width="800px">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;"><span style="font-weight: 700">{{ stuInfo.stu_name }}</span> 学生成绩列表</span>
                <el-button icon="el-icon-plus" style="margin-left:10px" size="medium" type="warning" plain
                    @click="addNew">新增</el-button>
            </div>

            <el-table v-tableFit :data="scoreList" row-key="id" height="50vh" size="small" stripe border
                style="width:100%;">
                <el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
                <el-table-column label="课程名称" prop="subject" min-width="200" align="center"></el-table-column>
                <el-table-column label="成绩" prop="score" width="100px" align="center"></el-table-column>
                <el-table-column label="排序号" prop="sort" width="150px" align="center"></el-table-column>
                <el-table-column label="操作" width="140" align="center">
                    <template slot-scope="scope">
                        <el-tag class="pointer" @click="handleModify(scope.row)" type="primary" size="mini"
                            style="margin-left:10px;">修改</el-tag>
                        <el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
                            iconColor="red" @confirm="del(scope.row)" placement="left" title="是否确定删除？">
                            <el-tag class="pointer ml10" slot="reference" size="mini" type="danger">删除</el-tag>

                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog ref="ruleForm" title="新增/修改成绩" :visible.sync="dialogForm" :close-on-click-modal="false"
            :modal-append-to-body="false" :destroy-on-close="true" custom-class="cus_dialog">

            <el-form :model="formData" label-width="120px" ref="formData">
                <el-form-item label="课程名称" required>
                    <el-input class="input_css" v-model="formData.subject"></el-input>
                </el-form-item>
                <el-form-item label="成绩" required>
                    <el-input class="input_css" v-model="formData.score"></el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input class="input_css" v-model="formData.sort"></el-input>
                </el-form-item>
            </el-form>
            <div class="" slot="footer">
                <el-button @click="onSubmit('formData')" type="primary">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import tchtree from "../com/tchTree.vue";
import moment from "moment";

export default {
    components: {
        tchtree
    },
    data() {
        return {
            searchForm: {},
            tableData: [],
            cityOptions: [],
            dialogForm: false,
            dialog1Form: false,
            formData: {},
            qyxz: [],
            isok: [],
            xzfw: [],
            xlyq: [],
            gzjy: [],
            wyyq: [],
            jsjyq: [],
            gwshzz: [],
            page: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            },
        
            visible: false,
            isShowModal: false,
            isShowModalAuth: false,
            modalTitle: "添加",
            activeRoleID: "",
            biye: 0,
            formData: {},
            formTeacher: {
                bzr: "",
                zdls: []
            },
            ckdTreeData: [],

            defaultProps: {
                label: "name",
                value: "id",
                children: "children",
                emitPath: false,
                checkStrictly: true
            },

            activeName: "CN",
            tableData: [],

            teacherList: [],
            editUser: {},
            deptId: 0,
            editDeptRole: {},

            page: {},
            searchForm: {
                biye: 0,
                ktype: "stu_name",
                keyword: ""
            },
            multipleSelection: [],
            treeData: [],
            firstCascaderProps: {
                label: "name",
                children: "children"
            },
            DataAuth: [],
            student_id: 0,
            showExpand: true,
            dictArr: [],
            schoolYears: [],
            showXxcj: false,
            stuid: 0,
            scoreList: [],
            stuInfo: {},
        };
    },
    mounted() {
        let _this = this
        this.getTree();
        this.getSchoolYear().then(_ => {
            this.getStudents();
        })
        // this.getComType().then(rr => {
        //     this.getList();
        //     this.cityOptions = citys;
        // });
    },
    methods: {
        getSchoolYear() {
            return new Promise((resolve, reject) => {
                if (this.$store.state.pmid == 215) {

                    let m = moment().format('M')
                    let nowYear = moment().year()
                    let schoolYears = []
                    if (m >= 5) {
                        nowYear++
                    }
                    for (let i = nowYear; i >= 2019; i--) {
                        let s = (i - 1) + "-" + i
                        let s_label = s + '学年(' + (i - 5) + '届)'
                        schoolYears.push({ title: s_label, value: s })
                    }
                    this.schoolYears = schoolYears
                    this.searchForm.school_year = schoolYears[0].value
                }

                resolve()
            })
        },
        getTree() {
            this.$http
                .post("/api/sys_office_tree", { parent_id: 0, biye: this.biye })
                .then(res => {
                    this.treeData = res.data.tree;
                    this.ckdTreeData = this.treeData;
                });
        },

        searchData() {
            this.page.current_page = 1;
            this.getStudents();
        },

        getStudents() {
            let params = {
                page: this.page.current_page,
                pagesize: this.page.per_page ? this.page.per_page : 20,
                ktype: this.searchForm.ktype,
                keyword: this.searchForm.keyword,
                // dept_id: this.searchForm.keyword ? '' : this.deptId,
                // class_id: this.searchForm.keyword ? '' : this.deptId,
                // office_id: this.searchForm.keyword ? "" : this.deptId,
                class_ids: this.searchForm.keyword ? "" : this.deptId,
                biye: this.searchForm.biye,
                school_year: this.searchForm.school_year,
            };

            // sys_dept_user_related_list
            this.$http.post("/api/sx_students_list", params).then(res => {
                res.data.data &&
                    res.data.data.map(a => {
                        this.dictArr.map(d => {
                            if (d.param_value == a.sxqx) {
                                a.sxqxLabel = d.param_desc;
                                //如果是实习中，检查是否在开始时间
                                if (a.sxqx == 1 && a.start_time && a.end_time) {
                                    let ntime = parseInt(
                                        this.$moment(new Date())
                                            .add("year", 0)
                                            .format("YYYY-MM-DD")
                                            .replace(/-/g, "")
                                    );
                                    let start_time = parseInt(a.start_time.replace(/-/g, ""));
                                    let end_time = parseInt(a.end_time.replace(/-/g, ""));
                                    // console.log(888,a.stu_name,a.sxqx,ntime,a.start_time)
                                    if (ntime >= start_time && ntime <= end_time) {
                                        console.log(111, a.stu_name);
                                        // a.sxqxLabel = '实习中'
                                    } else if (ntime > end_time) {
                                        console.log(222, a.stu_name);
                                        a.sxqxLabel = "实习结束";
                                    } else if (ntime < start_time) {
                                        console.log(333, a.stu_name);
                                        a.sxqxLabel = "待实习";
                                    }
                                }
                            }
                        });
                    });
                this.tableData = res.data.data;
                this.page = res.data.page;
            });
        },

        handleNodeClick(data) {
            // console.log(data);
            this.deptId = data.id;

            this.deptInfo = data;
            this.ckdTreeData = [data];
            this.getStudents();
        },
        handlePageChange(page) {
            this.page.current_page = page;
            this.getStudents();
        },
        handleSizeChange(val) {
            this.page.current_page = 1;
            this.page.per_page = val;
            this.getStudents()
        },




        viewScoreList(row) {
            this.stuInfo = JSON.parse(JSON.stringify(row))
            console.log(row)
            this.$http.post("/api/stu_examscore_list", { stu_id: row.id }).then(res => {
                if (res && res.data) {
                    this.scoreList = res.data;
                } else {
                    this.scoreList = [];
                }
            }).catch(_ => {
                this.scoreList = [];

            });
            this.dialog1Form = true;

        },

        getParentId(list, iid) {
            if (list && list.length > 0) {
                for (let o of list) {
                    if (o.value == iid) return o;
                    const o_ = this.getParentId(o.children, iid);
                    if (o_) return o_;
                }
            }
        },

        addNew() {
            this.formData = {}
            this.dialogForm = true
        },
        getComType() {
            return new Promise(resolve => {
                this.$http
                    .post("/api/sys_param_list", {
                        param_type:
                            "'ENTER_INFO','ISOK','SALARY_RANGE','EDUACTION','EXPERIENCE','ENGLISH_LEVEL','COMPUTER_LEVEL','GWSHZT'",
                        used: 0
                    })
                    .then(res => {
                        let qyxz = [];
                        let isok = [];
                        let xzfw = []; //薪资类型
                        let xlyq = []; //学历类型
                        let gzjy = []; //工作经验
                        let wyyq = []; //外语要求
                        let jsjyq = []; //计算机要求
                        let gwshzz = []; //计算机要求
                        for (let item of res.data) {
                            if (item.param_type == "ENTER_INFO") {
                                qyxz.push(item);
                            }
                            if (item.param_type == "ISOK") {
                                isok.push(item);
                            }
                            if (item.param_type == "SALARY_RANGE") {
                                xzfw.push(item);
                            }
                            if (item.param_type == "EDUACTION") {
                                xlyq.push(item);
                            }
                            if (item.param_type == "EXPERIENCE") {
                                gzjy.push(item);
                            }
                            if (item.param_type == "ENGLISH_LEVEL") {
                                wyyq.push(item);
                            }
                            if (item.param_type == "COMPUTER_LEVEL") {
                                jsjyq.push(item);
                            }
                            if (item.param_type == "GWSHZT") {
                                gwshzz.push(item);
                            }
                        }
                        this.qyxz = qyxz;
                        this.isok = isok;
                        this.xzfw = xzfw;
                        this.xlyq = xlyq;
                        this.gzjy = gzjy;
                        this.wyyq = wyyq;
                        this.jsjyq = jsjyq;
                        this.gwshzz = gwshzz;
                        resolve();
                    });
            });
        },
        handleModify(row) {
            this.formData = row;
            this.dialogForm = true;
        },

        del(row) {
            this.$http.post("/api/stu_examscore_delete", {
                id: row.id
            }).then(res => {
                this.$message.success('删除成功')
                this.viewScoreList(this.stuInfo);
            })
        },
        onSubmit() {
            if (!this.formData.subject) {
                this.$message.warning("请填写课程~")
                return
            }
            if (!this.formData.score) {
                this.$message.warning("请填写成绩~")
                return
            }
            let params = this.formData
            console.log(474, this.stuInfo)
            params.stu_code = this.stuInfo.stu_code
            params.stu_id = this.stuInfo.id
            params.sort = params.sort || 0
            this.$http.post("/api/stu_examscore_edit", params).then(res => {
                this.dialogForm = false;
                this.$message.success("保存成功")
                this.viewScoreList(this.stuInfo);
            });

        },


        uploadFiles(e) {
            let url = "/api/import_stu_examscore_xls"
            if (this.$store.state.pmid == 215){
                url = '/api/import_stu_examscore_xls_njcj'
            }
            this.$http
                .post(url, {
                    url: e.src
                })
                .then(res => {
                    this.$message.success("导入成功");
                    if (res.data.errmsg && res.data.errmsg.length > 0) {
                        let html = "";
                        for (let msg of res.data.msg) {
                            html +=
                                "<div style='font-size:12px'>" +
                                msg["姓名"] +
                                ":" +
                                msg.msg +
                                "</div>";
                        }
                        this.$alert(html, "未导入数据", {
                            dangerouslyUseHTMLString: true
                        });
                    }
                    this.getList();
                });
        },
        tongbuJava() {
            return
            this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let loadingInstance = this.$loading({
                    lock: true,
                    text: "已发送请求，请稍等...",
                    spinner: 'el-icon-loading'
                })
                this.$http.post("/api/sync_enterprise", {}).then(res => {
                    loadingInstance.close()
                    this.$message.success('后台同步中，请刷新查看')
                    this.getList();
                }).catch(err => {
                    this.$message.error('同步失败')
                    loadingInstance.close()
                })

            }).catch(() => {

            });
        },

    }
};
</script>
<style lang="less" type="text/less" scoped>
.input_css {
    width: 430px;
}

.tyclogo0 {
    width: 15px;
    height: 15px;
    filter: grayscale(100%);
}

.tyclogo1 {
    width: 15px;
    height: 15px;
}
</style>
